.image {
  filter: grayscale(100%);
}

.image:hover {
  filter: grayscale(0%);
}

/* .image-teacher:hover {
  filter: grayscale(100%);
} */

.image-teacher {
  filter: brightness(70%);
}

.image-teacher:hover {
  filter: brightness(100%);
}
