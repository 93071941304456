.allow-multiple-items {
    position: absolute;
    top: 5%;
    left: 6%;
    width: 40%;
    height: 80%;
}
.allow-multiple-items .dragItem {
    width: 54%;
    height: 9%;
}

img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

.dragItem:nth-child(1) {
    top: 0;
    left: 0;
}

.dragItem:nth-child(2) {
    top: 10%;
    left: 0;
}

.dragItem:nth-child(3) {
    top: 20%;
    left: 0;
}

.dragItem--active {
    filter: brightness(65%);
}

.dropZone--active {
	border: 2px dashed gray;
    opacity: 40%;
}

.dragItem {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.redCode {
    top: 0;
    left: 0; 
    background-image: url("../../assets/BasicsOfProgramming/backcolor_red.png");
}

.yellowCode {
    top: 0;
    left: 0; 
    background-image: url("../../assets/BasicsOfProgramming/backcolor_yellow.png");
}

.greenCode {
    top: 0;
    left: 0; 
    background-image: url("../../assets/BasicsOfProgramming/backcolor_green.png");
}
.codeZone {
    position: absolute;
    width: 21%;
    height: 7%;
}

#red {
    top: 26%;
    right: 27%;
}
#yellow {
    top: 48%;
    right: 27%;
}
#green {
    top: 69%;
    right: 27%;
}

.image-button {
    position: absolute;
    border: none;
}

.openButton {
    background: url('../../assets/BasicsOfProgramming/run_button.png');
    width: 4.5%;
    height: 8.5%;
    top: 5.5%;
    right: 4%;
    background-repeat: no-repeat;
    background-size: contain;
}

.modalOpen {
    background: url('../../assets/BasicsOfProgramming/stop_button.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.closeModal {
    background: url('../../assets/BasicsOfProgramming/close_button.png');
    background-repeat: no-repeat;
    background-size: contain;
    top: 2%;
    right: 2%;
    width: 6%;
    height: 10%;
}

/* .button {
    font-size: 1rem;
    position: absolute;
    top: 82%;
    right: 10%;
} */

.image-button:hover {
    filter: brightness(85%);
}

.modalWindow {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 49%;
    transform: translate(-50%, -50%);
}

#modalContent {
    background-repeat: no-repeat;
    background-size: contain;
    padding: 20px;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
}

.targetElement {
    width: 27%;
    height: 16%;
}

#redButton {
    background: url(../../assets/BasicsOfProgramming/button_constructor_red.png);
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    top: 29%;
    right: 5%;
}

#yellowButton {
    background: url(../../assets/BasicsOfProgramming/button_constructor_yellow.png);
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    top: 49%;
    right: 5%;
}

#greenButton {
    background: url(../../assets/BasicsOfProgramming/button_constructor_green.png);
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    top: 68%;
    right: 5%;
}

.redButtonClicked {
    background-image: url(../../assets/BasicsOfProgramming/form_empty_red.png);
}

.yellowButtonClicked {
    background-image: url(../../assets/BasicsOfProgramming/form_empty_yellow.png);
}

.greenButtonClicked {
    background-image: url(../../assets/BasicsOfProgramming/form_empty_green.png);
}

.codeZoneC  {
    position: absolute;
    height: 5%;
}
.codeZoneC input {
    color:lightgray;
    background: none;
    border: none;
    position: absolute;
    outline:none;
    font-size: 110%;
}

#redZone {
    width: 24%;
    top: 27.5%;
    right: 10%;
}
#yellowZone {
    width: 29%;
    top: 49%;
    right: 10%;
}
#greenZone {
    width: 38%;
    top: 70%;
    right: 10%;
}

#textInputRed {
    width: 100%;
}
#textInputYellow {
    width: 100%;
}
#textInputGreen {
    width: 100%;
}