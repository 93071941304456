.container-textbox {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.box-character-name {
  position: absolute;
  border-radius: 5px;
  padding: 1% 4%;
  top: 0px;
  color: black;
  font-size: 140%;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3), 2px 1px 1px rgba(255, 255, 255, 1);
  z-index: 2;
  left: 10%;
  max-height: 40%;
  max-width: 50%;
}

.box-dialogue-text {
  position: absolute;
  border-radius: 10px;
  color: white;
  font-size: 110%;
  background: rgba(0, 0, 0, 0.8);
  width: 90%;
  height: 80%;
  padding: 3.5% 4%;
  top: 15%;
  max-height: 80%;
}
@media (max-width: 1400px) and  (min-width: 750px){
  .box-character-name {
    font-size: 1.1vw;
  }
  .box-dialogue-text {
    font-size: 0.8vw;
  }
}

@media (max-width: 750px) {
  .box-character-name {
    font-size: 1.1vw;
  }
  .box-dialogue-text {
    font-size: 0.6vw;
  }
}
.choice {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2px;
  cursor: pointer;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  color: rgb(190, 190, 190);
  background: rgba(255, 255, 255, 0.1);
}

.choice:hover {
  color: white;
  background: rgba(0, 0, 0, 0.6);
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
