.button-items {
    position: absolute;
    height: 35%;
    width: 30%;
    top: 80.5%;
    left: 12%;
    display: flex;
}

.button-work {
    position: absolute;
    height: 35%;
    width: 30%;
    top: 80.5%;
    right: -1.5%;
    display: flex;
}

.panel-button {
    height: 34.5%;
    width: 28%;
    border: none;
}

.panel-button:hover {
    filter: brightness(80%);
    cursor: pointer;
}

#pc-button {
    background: url('../../assets/ComputerNetworks/button_pc.png');
    background-size: cover;
    background-position: 0 0;
}
#line-button {
    background: url('../../assets/ComputerNetworks/button_line.png');
    background-size: cover;
    background-position: 0 0;
}
#commutator-button {
    background: url('../../assets/ComputerNetworks/button_commutator.png');
    background-size: cover;
    background-position: 0 0;
}
#work-done {
    background: url('../../assets/ComputerNetworks/button_accept.png');
    background-size: cover;
    background-position: 0 0;
}
#work-done:hover {
    background: url('../../assets/ComputerNetworks/button_accept.png');
    background-size: cover;
    background-position: -93% 0;
}
#abort {
    background: url('../../assets/ComputerNetworks/button_discard.png');
    background-size: cover;
    background-position: 0 0;
}
#abort:hover {
    background: url('../../assets/ComputerNetworks/button_discard.png');
    background-size: cover;
    background-position: -96% 0;
}

.pc {
    background: url('../../assets/ComputerNetworks/icon_pc.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 12%;
    height: 16%;
    border: none;
    position: absolute;
}

#pc1 {
    left: 28%;
    top: 26%;
}

#pc2 {
    left: 28%;
    top: 52%;
}
.pc:hover {
    filter: brightness(80%);
    cursor: pointer;
}

.line, .commutator {
    position: absolute;
}

#line1 {
    background: url('../../assets/ComputerNetworks/line_connection_1.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 10%;
    width: 15%;
    top: 40%;
    left: 38%;
}
#line2 {
    background: url('../../assets/ComputerNetworks/line_connection_2.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 24%;
    width: 24%;
    top: 47%;
    left: 37.5%;
}

.commutator {
    background: url('../../assets/ComputerNetworks/icon_commutator.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 35%;
    width: 30%;
    width: 12%;
    height: 11%;
}

#commutator {
    top: 40.5%;
    left: 49%;
}

.window1 {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.window2 {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    z-index: 1;
}


.modalContent1 {
    background-image: url(../../assets/ComputerNetworks/settings_ip4_full.png);
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    position: relative;
}

.modalContent2 {
    background-image: url(../../assets/ComputerNetworks/settings_ip4_full.png);
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    position: relative;
}

.console-background {
    background-image: url(../../assets/ComputerNetworks/settings_console_full.png);
}

.ipv4 {
    position: absolute;
    top:37%;
    right: -12%;
    width: 100%;
    height: 100%;
}

.ipv4 input {
    color: black;
    font-size: calc( 1vw);
    width: 52%;
    height: 6%;
    border: none;
    border-radius: 10px;
    background: none;
}
.console-button {
    position: absolute;
    cursor: pointer;
    height: 11.5%;
    width: 30%;
    background: none;
    border: none;
}
.console-button:hover {
    background: url('../../assets/ComputerNetworks/settings_button.png');
    background-size: cover;
    background-position: 0 0;
}

#buttonConsolePC1 {
    position: absolute;
    top: 56%;
    left: 2%;
}
#buttonConsolePC2 {
    position: absolute;
    top: 56%;
    left: 3%;
}
#buttonIPv4PC1 {
    position: absolute;
    top: 2%;
    left: 3%;
}
#buttonIPv4PC2 {
    position: absolute;
    top: 2%;
    left: 3%;
}
#textConsolePC1 {
    position: absolute;
    top: 16%;
    left: 45%;
    width: 130px;
    height: 200px;
}
#textConsolePC2 {
    position: absolute;
    top: 16%;
    left: 45%;
    width: 130px;
    height: 200px;
}

textarea {
    resize: none;
    border: none;
    background: none;
}

#messagePC1 {
    color: black;
    position: absolute;
    top:80%;
    left: 35%;
    margin: 5px 10px;
    width: 40%;
    text-align: left;
    font-size: calc(0.8vw);
}

#messagePC2 {
    color: black;
    position: absolute;
    top:80%;
    left: 35%;
    margin: 5px 10px;
    width: 40%;
    text-align: left;
    font-size: calc(0.8vw);
}