.pulse,
.bloc {
  position: absolute;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  width: 90px;
  height: 90px;
}
.bloc {
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
}
.pulse {
  background: #579ce5;
  position: relative;
  transition: 0.5s;
}

@media (max-width: 800px) {
  .pulse,
  .bloc {
    width: 70px;
    height: 70px;
  }
  .text {
    font-size: 0.7rem;
  }

}

.abc {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.text {
  position: absolute;
  opacity: 0;
  text-transform: uppercase;
  font-weight: 700;
  top: 28px;
  /* font-size: 15px; */
  transform: scaleX(-1);
  transition: 0.5s ease-in-out;
}

.pulse:hover {
  background: #579ce5;
}
.pulse:hover i {
  transition: 0.5s ease-in-out;
  transform: scale(0);
}
.pulse:hover .text {
  transform: scaleX(1);
  transition: 0.5s ease-in-out;
  opacity: 1;
}
.pulse::before,
.pulse::after {
  content: "";
  position: absolute;
  border: 1px solid #579ce5;
  left: -20px;
  right: -20px;
  top: -20px;
  bottom: -20px;
  border-radius: 50%;
  animation: pulse 1.8s linear infinite;
  opacity: 0;
}
.pulse::after {
  animation-delay: 0.5s;
}
.pulse:hover::after {
  animation-delay: 0.5s;
}
.pulse:hover .abc svg {
  transition: 0.5s ease-in-out;
  transform: scale(0);
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.imgEvdokiaCircle {
  position: absolute;
  top: 33%;
  width: 65%;
}

.cloudBlock {
  position:absolute;
  top:7%;
  left:28%;
  width: 75%;
  transform: scale(-1, 1);
}

.studBook {
  margin: 32% 5% 5%;
  width: 30%;
  transform: rotate(-25deg);
  transition: transform 0.3s ease-in-out;
}

.studBook.studBook:hover {
  transform: rotate(0deg);
}

@media (max-width: 1200px) {
  .cloudBlock {
    width: 65%;
    top:0;
    left:28%;
  }
  .imgEvdokiaCircle {
    width: 55%;
  }
}

@media (max-width: 800px) {
  .studBook {
    margin: 42% 5% 0%;
  }
}

