.card-carusels-home {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 350px;
  width: 100%;
  margin: 5% auto;
  perspective: 1000px;
  overflow: hidden;
}
.card-carusels-home .item-home {
  width: 45%;
  height: auto;
  border-radius: 5px;
  position: absolute;
  box-sizing: border-box;
  text-align: center;
  transition: transform 0.4s;
  user-select: none;
  cursor: pointer;
  vertical-align: middle;
  
}

@media (max-width: 600px) {
  .item-home h2 {
    font-size: 4vw;
  }
  .card-carusels-home {
    min-height: 35vw;
  }
  .card-carusels-home .item-home {
    margin-top: 3%;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .card-carusels-home {
    min-height: 30vw;
  }
  .card-carusels-home .item-home {
    margin-top: 3%;
  }
  .item-home h2 {
    font-size: 3.5vw;
  }
}

@media (max-width: 1200px)and (min-width: 800px) {
  .card-carusels-home {
    min-height: 25vw;
  }
}

.dots-home {
  display: none;
  justify-content: center;
  align-items: center;
}
.dots-home label {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgb(18, 18, 18);
  margin: 7px;
  transition-duration: 0.2s;
  margin-bottom: 50px;
}
#t-1:checked ~ .card-carusels-home .item-home[for="t-1"],
#t-2:checked ~ .card-carusels-home .item-home[for="t-2"],
#t-3:checked ~ .card-carusels-home .item-home[for="t-3"] {
  transform: translate3d(0, 0, 0);
  z-index: 4;
}

#t-1:checked ~ .card-carusels-home .item-home[for="t-2"],
#t-2:checked ~ .card-carusels-home .item-home[for="t-3"],
#t-3:checked ~ .card-carusels-home .item-home[for="t-1"] {
  transform: translate3d(-90%, 0, -20rem);
  filter: blur(0.5px);
  z-index: 3;
}

#t-1:checked ~ .card-carusels-home .item-home[for="t-3"],
#t-2:checked ~ .card-carusels-home .item-home[for="t-1"],
#t-3:checked ~ .card-carusels-home .item-home[for="t-2"] {
  transform: translate3d(90%, 0, -20rem);
  filter: blur(0.5px);
  z-index: 3;
}

.cardsNews {
  margin: 2% 1%;
  box-shadow: 0px 0px 37px 10px rgba(0, 0, 0, 0.26);
  background: rgba(18, 18, 18, 0.5);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  width: calc(30%);
}

.cardsNews:hover {
  transform: scale(1.03);
}

@media (max-width: 900px) {
  .cardsNews {
    width: calc(60%);
    margin: 2.5% 1%;
  }
}

