.link-item {
  color: rgb(235, 235, 235);
}

.link-item-logo {
  transition: 0.5s;
}

.link-item a {
  margin: 0 1em;
  color: #ffffff;
  text-decoration: none;
  padding: 10px;
}

.link-item a:hover {
  background-color: #ffffff;
  color: #000;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.link-item-logo:hover {
  transform: scale(1.2);
}
.link-item-sign {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}
.link-item-sign-menu {
  padding-top: 1em;
  padding-bottom: 0.5em;
  padding-left: 2em;
}
.link-item-sign-menu a {
  color: #ffffff;
  text-decoration: none;
  padding: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #ffffff;
  border-radius: 8px;
}

.link-item-sign-menu a:hover {
  background-color: rgb(235, 235, 235);
  color: #141414;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.link-item-sign a {
  color: #ffffff;
  text-decoration: none;
  padding: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #ffffff;
  border-radius: 8px;
}

.link-item-sign a:hover {
  background-color: rgb(235, 235, 235);
  color: #141414;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.box-style {
  display: flex;
  list-style-type: none;
  justify-content: center;
  width: 100%;
  margin-left: 270px;
}
