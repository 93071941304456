.card-carusels {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 350px;
  height: 28vw;
  width: 100%;
  perspective: 1000px;
  overflow: hidden;
}

.card-carusels .item {
  width: 25%;
  min-width: 50px;
  height: 90%;
  border-radius: 5px;
  position: absolute;
  top: 0;
  box-sizing: border-box;
  text-align: center;
  transition: transform 0.4s;
  user-select: none;
  cursor: pointer;
}
.dots {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dots label {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgb(18, 18, 18);
  margin: 7px;
  transition-duration: 0.2s;
  margin-bottom: 50px;
}
@media (min-width: 900px) {
  #t-1:checked ~ .dots label[for="t-1"],
  #t-2:checked ~ .dots label[for="t-2"],
  #t-3:checked ~ .dots label[for="t-3"],
  #t-4:checked ~ .dots label[for="t-4"],
  #t-5:checked ~ .dots label[for="t-5"] {
    transform: scale(2);
    background-color: #fff;
    box-shadow: 0px 0px 0px 3px #dddddd24;
  }

  #t-1:checked ~ .dots label[for="t-2"],
  #t-2:checked ~ .dots label[for="t-1"],
  #t-2:checked ~ .dots label[for="t-3"],
  #t-3:checked ~ .dots label[for="t-2"],
  #t-3:checked ~ .dots label[for="t-4"],
  #t-4:checked ~ .dots label[for="t-3"],
  #t-4:checked ~ .dots label[for="t-5"],
  #t-5:checked ~ .dots label[for="t-4"] {
    transform: scale(1.5);
  }

  #t-1:checked ~ .card-carusels label[for="t-3"],
  #t-2:checked ~ .card-carusels label[for="t-4"],
  #t-3:checked ~ .card-carusels label[for="t-5"],
  #t-4:checked ~ .card-carusels label[for="t-1"],
  #t-5:checked ~ .card-carusels label[for="t-2"] {
    transform: translate3d(160%, 0, -180px) rotateY(-25deg);
    z-index: 2;
  }

  #t-1:checked ~ .card-carusels label[for="t-2"],
  #t-2:checked ~ .card-carusels label[for="t-3"],
  #t-3:checked ~ .card-carusels label[for="t-4"],
  #t-4:checked ~ .card-carusels label[for="t-5"],
  #t-5:checked ~ .card-carusels label[for="t-1"] {
    transform: translate3d(80%, 0, -90px) rotateY(-15deg);
    z-index: 3;
  }

  #t-2:checked ~ .card-carusels label[for="t-1"],
  #t-3:checked ~ .card-carusels label[for="t-2"],
  #t-4:checked ~ .card-carusels label[for="t-3"],
  #t-5:checked ~ .card-carusels label[for="t-4"],
  #t-1:checked ~ .card-carusels label[for="t-5"] {
    transform: translate3d(-80%, 0, -90px) rotateY(15deg);
    z-index: 3;
  }

  #t-3:checked ~ .card-carusels label[for="t-1"],
  #t-4:checked ~ .card-carusels label[for="t-2"],
  #t-5:checked ~ .card-carusels label[for="t-3"],
  #t-2:checked ~ .card-carusels label[for="t-5"],
  #t-1:checked ~ .card-carusels label[for="t-4"] {
    transform: translate3d(-160%, 0, -180px) rotateY(25deg);
  }

  #t-1:checked ~ .card-carusels label[for="t-1"],
  #t-2:checked ~ .card-carusels label[for="t-2"],
  #t-3:checked ~ .card-carusels label[for="t-3"],
  #t-4:checked ~ .card-carusels label[for="t-4"],
  #t-5:checked ~ .card-carusels label[for="t-4"],
  #t-5:checked ~ .card-carusels label[for="t-5"] {
    z-index: 4;
  }
}

/* @media (max-width: 900px) and (min-width: 700px) {
  .card-carusels {
    flex-direction: column;
    align-items: center;
    min-height: 45vw;
  }

  .card-carusels .item {
    width: 35%;
  }
  .dots label {
    height: 10px;
    width: 10px;
  }
  #t-1:checked ~ .dots label[for="t-1"],
  #t-2:checked ~ .dots label[for="t-2"],
  #t-3:checked ~ .dots label[for="t-3"],
  #t-4:checked ~ .dots label[for="t-4"],
  #t-5:checked ~ .dots label[for="t-5"] {
    transform: none;
    z-index: 1;
  }
}  */

@media (max-width: 900px) and (min-width: 450px) {
  .card-carusels {
    flex-direction: column;
    align-items: center;
    perspective: none;
    height: 200vh;
  }

  .card-carusels .item {
    flex-wrap: wrap;
    position: relative; 
    transform: none; 
    margin-bottom: 20px; 
    width: 75%;
    min-height: 200px;
  }

  .dots {
    display: none; 
  }

  #t-1:checked ~ .dots label[for="t-1"],
  #t-2:checked ~ .dots label[for="t-2"],
  #t-3:checked ~ .dots label[for="t-3"],
  #t-4:checked ~ .dots label[for="t-4"],
  #t-5:checked ~ .dots label[for="t-5"] {
    transform: none; 
    background-color: rgb(18, 18, 18);
    box-shadow: none;
  }
}
@media (max-width: 450px) {
  .card-carusels {
    flex-direction: column;
    align-items: center;
    perspective: none; 
    height: 160vh;
  }

  .card-carusels .item {
    flex-wrap: wrap;
    position: relative;
    transform: none;
    margin-bottom: 20px; 
    width: 100%;
    /* min-width: 150px; */
    min-height: 150px;
  }

  .dots {
    display: none;
  }

  #t-1:checked ~ .dots label[for="t-1"],
  #t-2:checked ~ .dots label[for="t-2"],
  #t-3:checked ~ .dots label[for="t-3"],
  #t-4:checked ~ .dots label[for="t-4"],
  #t-5:checked ~ .dots label[for="t-5"] {
    transform: none !important; 
    background-color: rgb(18, 18, 18) !important;
    box-shadow: none !important;
  }
}
