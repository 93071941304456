.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1em;
  width: 100%;
  height: 75vh;
  transition: all 400ms;
}

.box {
  position: relative;
  background: var(--img) center center;
  background-size: var(--img);
  background-color: rgb(65, 65, 65);
  background-repeat: no-repeat;
  transition: all 400ms;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box::after {
  content: attr(data-text);
  position: absolute;
  bottom: 20px;
  background: #000;
  color: #fff;
  padding: 10px 10px 10px 14px;
  letter-spacing: 4px;
  text-transform: uppercase;
  transform: translateY(60px);
  opacity: 0;
  transition: all 400ms;
}

.container:hover .box {
  filter: grayscale(80%) opacity(44%);
}

.box:hover::after {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 400ms;
}

.container .box:hover {
  filter: grayscale(0%) opacity(100%);
}

.container:has(.box-1:hover) {
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
}

.container:has(.box-2:hover) {
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr;
}

.container:has(.box-3:hover) {
  grid-template-columns: 1fr 1fr 3fr 1fr 1fr 1fr;
}

.container:has(.box-4:hover) {
  grid-template-columns: 1fr 1fr 1fr 3fr 1fr 1fr;
}

.container:has(.box-5:hover) {
  grid-template-columns: 1fr 1fr 1fr 1fr 3fr 1fr;
}

.container:has(.box-6:hover) {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 3fr;
}

.box:nth-child(odd) {
  transform: translateY(-16px);
}

.box:nth-child(even) {
  transform: translateY(16px);
}
